import ApiService from './ApiService';
// import Repository from './Repository'

class     SettingService extends ApiService {
    resource = '/setting';

    getFBSecret() {
        return this.getArr(`${this.resource}/secret`);
    }

    
}

const settingService = new SettingService;
export { settingService };
export default SettingService;
